import logo from './logo.svg';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import useQRCodeScan from './components/useQRCodeScan';
import { Grid } from '@mui/material';

import Modal from '@mui/material/Modal';

import './App.css';

function App() {
  const [leftKnee, setLeftKnee] = useState("5498461FWD");
  const [rightKnee, setRighttKnee] = useState("6854684sEF");
  const [qrcodeOpen, setQrcodeOpen] = useState(false);

  var onScanSuccess = (decodedText) => {
    // Handle on success condition with the decoded text or result.
    console.log(`Scan result: ${decodedText}`);
    try {
      const ws = new WebSocket(decodedText);
      ws.onopen = () => {
        // on connecting, do nothing but log it to the console
        console.log('connected')
        var kneeInfo = {
          leftKnee,
          rightKnee
        }
        var message = {
          sender: "smartphone",
          info: kneeInfo
        }
        ws.send(JSON.stringify(message));
        ws.close();
      }
      ws.onmessage = evt => {
        // listen to data sent from the websocket server
        const message = JSON.parse(evt.data);
        console.log(message);
      }
    }
    catch (ex) {
      console.log(ex)
    }

    stopQrCode();
    setQrcodeOpen(false);
  }

  const { startQrCode, decodedQRData, stopQrCode } = useQRCodeScan({
    qrcodeMountNodeID: "qrcodemountnode",
    onScanSuccess: onScanSuccess,
  });

  const toggleQrCode = () => {
    setQrcodeOpen(true);
    startQrCode();
  }




  return (
    <div className="App">
      <header className="App-header">
        <p>
          Smartphone Web App
        </p>
      </header>
      <div className="App-content">
        <div id="qrcodemountnode" style={qrcodeOpen ? {} : { visibility: "hidden", height: 0 }}></div>
        {!qrcodeOpen ?
          <div className="inputs" style={{ paddingTop: "20px" }}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <TextField className="inputs-item" id="left-knee-id" value={leftKnee} label="Left Knee ID" onChange={(e) => setLeftKnee(e.target.value)} variant="outlined" />
              </Grid>
              <Grid item>
                <TextField className="inputs-item" id="right-knee-id" value={rightKnee} label="Right Knee ID" onChange={(e) => setRighttKnee(e.target.value)} variant="outlined" />
              </Grid>
              <Grid item>
                <Button className="inputs-item" onClick={toggleQrCode} variant="contained">SCAN QRCODE</Button>
              </Grid>
            </Grid>
            <p className="inputs-item">QRCode Data : {decodedQRData.data}</p>
          </div>
          :
          null
        }

      </div>
    </div >
  );
}

export default App;
